import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Color from 'color'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#ffffff',
      neutralLight3: '#F7F7F7',
      neutralLight2: '#DEDEDE',
      neutralLight1: '#E7E9EB',
      neutralDark4: '#666666',
      neutralDark3: '#535A69',
      neutralDark2: '#22242B',
      neutralDark1: '#1E2126',
      //primaryLight4: '#F3F7F7',
      //primaryLight3: '#E0F1EF',
      //primaryLight2: '#B4D9D5',
      primaryLight1: '#B4AFAC',
      //primaryDark4: '#86C8C3',
      //primaryDark3: '#81C0BB',
      //primaryDark2: '#76AFAC',
      primaryDark1: '#FF9966',
      //successLight1: '#6FCF97',
      dangerLight1: '#EB5757',
    },
    getHoverColor: (color: string, strength = 0.1): string => {
      const c = Color(color)
      return c.darken(strength).hex()
    },
  },
  fontFamily: {
    heading: "'Caudex', serif",
    paragraph: "'Libre Franklin', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
